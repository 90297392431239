import Vue from "vue";
import Vuex from "vuex";
//import auth from "./store.auth";
//import localAuth from "./localAuth";
import user from "./user";
import tags from "./tags";
import forms from "./forms";
import solicitation from "./solicitation";
import rules from "./rules";
import emailSync from "./emailSync";
import categories from "./categories";
import interaction from "./interaction";
import solicitationType from "./solicitationType";
import provider from "./provider";
import plans from "@/store/plans";
import group from "./group";
import icon from "./icon";
import configurationCompany from "./configurationCompany";
import company from "@/store/company";
import fields from "@/store/fields";
import approvalRules from "@/store/approvalRules";
import packages from "@/store/packages";
import persons from "@/store/persons";
import account from "@/store/account";
import device from "@/store/device";
import ddd from "@/store/ddd";
import onlineManager from "./onlineManager";
import menu from "@/store/menu";
import userGroup from "@/store/userGroup";
import automation from "./automation";
import pendencyConfiguration from "./pendencyConfiguration";
import tag from "./tag";
import dashboard from "./dashboard";

Vue.use(Vuex);

// const requireModule = require.context(
//   // The path where the service modules live
//   "./services",
//   // Whether to look in subfolders
//   false,
//   // Only include .js files (prevents duplicate imports`)
//   /.js$/
// );
// const servicePlugins = requireModule
//   .keys()
//   .map(modulePath => requireModule(modulePath).default);

const index = {
  namespaced: true,
  state: () => ({}),
  actions: {
    setErrorMessage({ commit }, { error }) {
      commit("SET_ERROR_MESSAGE", { error });
    }
  },
  mutations: {
    ["SET_ERROR_MESSAGE"](state, { error }) {
      state.errorMessage = error;
    }
  }
};

export default new Vuex.Store({
  modules: {
    index,
    //localAuth,
    user,
    forms,
    solicitation,
    rules,
    pendencyConfiguration,
    categories,
    interaction,
    solicitationType,
    provider,
    plans,
    group,
    icon,
    company,
    fields,
    configurationCompany,
    approvalRules,
    packages,
    persons,
    ddd,
    emailSync,
    onlineManager,
    menu,
    account,
    userGroup,
    tags,
    automation,
    tag,
    dashboard,
    device
  }
  //plugins: [...servicePlugins, auth]
});
